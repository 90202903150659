@font-face { font-family: Neue Haas Grotesk Display; src: url("fonts/NeueHaasDisplayRoman.ttf"); }
@font-face { font-family: Destiny Keys; src: url("fonts/Destiny_Keys.otf"); }

:root{
  --light: #e5d163;
  --bungie-net-blue: #479ce4;
  --badge-color-error: #aa2444;
  --badge-color: #8091cb;
  --badge-color-developer: #1995ff;
  --font-display: "Neue Haas Grotesk Display", "Destiny Keys";
  --off-white: #f2fffe;
}

* {
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: border-box;

  /* VARIABLES */
  font-family: var(--font-display);
}

html{
  background-color: #141414;
}

.footer{
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #f2fffe;
  align-items: center;

  padding-bottom: 2rem;
}

.app {
  padding-top: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #141414;
  color: #f2fffe;
  
  background-repeat: no-repeat;
  background-size: cover;
}

.head{
  margin-bottom: 1.5rem;
}

.mid{
  margin-left: 15%;
  margin-right: auto;
}

a:not(.pgcr-link){
  color: #f2fffe;
  text-shadow: none;
  transition: all 0.2s cubic-bezier(0.275, 0.485, 0, 1);
}

a:hover:not(.pgcr-link) {
  color: #f2fffe;
  text-shadow: 0px 0px 4px #f2fffe;
}

h1{
  letter-spacing: 0.5px;
}

.search {
  background: #1f2123;
  display: flex;

  outline: 1px solid #fff5;
  
  transition: all 0.2s cubic-bezier(0.275, 0.485, 0, 1);
}

.search:hover {
  outline: 3px solid #fff;
}

.search input{
  flex: 1;
  background: none;
  color: #f2fffe;
  padding-right: 1rem;
  font-weight: 500;
  font-size: 14px;
  padding: 0.5rem 1.75rem;

  border: none;
  outline: none;
}

.top-container{
  display: flex;
  width: 75%;
  justify-content: space-between;
  align-items: center;
}

.title-container {
  left: 4rem;
}

.title-container h2 {
  font-size: 48px;
  letter-spacing: 1px;
}

.title-container h3 {
  font-size: 26px;
  letter-spacing: 0.5px;
}

.button-container {
  margin-top: 1rem;
  text-align: center;
}

.button-container p {
  display: inline;
  align-items: center;
  font-size: 18px;
}

button {
  background: #fff1;
  display: flex;
  padding: 0.5rem 1.2rem;
  margin: .5rem;
  color: #f2fffe;
  display:inline;

  outline: 2px solid #fff8;
  
  transition: all 0.2s cubic-bezier(0.275, 0.485, 0, 1);
}

button:hover {
  outline: 4px solid #fff;
  background: #fff3;
  cursor: pointer;
}

.guardian-container{
  margin-left: auto;
  margin-right: 5%;
  margin-top: 1rem;
}

.guardian-container .name{
  margin-left: 19rem;
  text-shadow: 0px 2px 1px #0004;
}

.bungie-tag {
  color: var(--bungie-net-blue);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.matches-container {
  background-color: #0006;
  margin: 0.5rem;
  padding: 1rem;
  width: 25rem;
  outline: 2px solid #fff5;
}

.matches-container h2 {
  text-align: center;
}

.friend-card {
  height: 46px;
  margin: 0.5rem;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  display: flex;

  user-select: none;

  position: relative;
  overflow: hidden;
  outline-offset: 10px;
  outline: 0px solid #0000;
  border: 1px solid #fff;
  background-color: #0008;

  transition: all 0.2s cubic-bezier(0.275, 0.485, 0, 1);
}

.friend-card:hover {
  outline: 2px solid #fff;
  outline-offset: 4px;
  background-color: #767676;
  cursor: pointer;
}

.friend-card .emblem{
  box-shadow: 0px 0px 10px #141414;
}

.friend-card .details {
  position: absolute;
  left: 3.25rem;
  top: 25%;

  text-shadow: 0px 2px 1px #0004;
}

.friend-card .bungie-tag {
  font-size: 14px;
}

.friend-card .stats{
  position: absolute;
  right: 2rem;
  top: 10%;
  text-align: right;

  display: flex;
}

.friend-card .stats .stat{
  font-weight: 400;
  margin-left: 1.5rem;
}

.friend-card .stats .stat .stat-name{
  font-weight: 300;
  font-size: 12px;
  opacity: 0.6;
}

.guardian {
  width: 474px;
  height: 84px;
  margin: 1.1rem;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  display: flex;

  user-select: none;

  position: relative;
  overflow: hidden;
  outline-offset: 10px;
  outline: 0px solid #0000;
  border: 1px solid #fff;

  transition: all 0.2s cubic-bezier(0.275, 0.485, 0, 1);
}

.guardian:hover {
  outline: 2px solid #fff;
  outline-offset: 4px;
  background-color: #767676;
  cursor: pointer;
}

.guardian-details {
  position: absolute;
  left: 18%;
  top: 7%;

  text-shadow: 0px 2px 1px #0004;
}

.guardian-light {
  color: var(--light);
  position: absolute;
  font-weight: 400;
  font-size: 22px;
  top: 7%;
  right: 3%;

  text-shadow: 0px 3px 2px #0004;
}

.page-button-container {
  display: flex;
  align-items: center;
}

.pgcr-search-container .image{
  size: 12rem;
}

.pgcr-entry .details {
  position: absolute;
  left: 8rem;
  top: 12%;
  font-size: 16px;
  letter-spacing: 1px;

  text-shadow: 0px 2px 1px #0004;
}

.pgcr-entry .date{
  position: absolute;
  right: 3rem;
  top: 24%;
  text-align: right;

  display: flex;
}

.pgcr-container, .pgcr-search-container{
  margin-top: 1rem;
  margin-bottom: 1rem;
  transition: all 0.5s cubic-bezier(0.275, 0.485, 0, 1);
  width: 82%;
}

.pgcr-container .column, .column-team-1, .column-team-2{
  width: 100%;
  transition: all 0.5s cubic-bezier(0.275, 0.485, 0, 1);
  margin-bottom: 1rem;
}

.pgcr-container .column-team-2 .pgcr-player{
  background-color: #f4433620;
}

.pgcr-container .column-team-1 .pgcr-player{
  background-color: #0096db20;
}

.pgcr-container .column-team-1 .pgcr-player:hover, .pgcr-container .column-team-2 .pgcr-player:hover{
  background-color: #fff5;
}

.team-details{
  display: flex;
  justify-content: space-between;

  padding-right: 1rem;
  padding-left: .25rem;
}

@media screen and (min-width: 1660px) {
  .pgcr-container {
    display: flex;
  }
}

.title-container .name{
  text-shadow: 0px 2px 1px #0004;
  letter-spacing: 2px;
}

.title-container .sub-name{
  text-shadow: 0px 2px 1px #0004;
  letter-spacing: 1px;
  opacity: 0.8;
  font-weight: 300;
}

.rectangle {
  display: inline-block;
  margin-top: 1rem;
  width: 50px;
  height: 5px;
  background: var(--off-white);
  opacity: 0.85;
}

.pgcr-player, .pgcr-entry {
  height: 4.25rem;
  margin-top: 0.75rem;
  margin-right: 0.75rem;
  min-width: 40rem;
  display: flex;
  user-select: none;
  color: #f2fffe;

  position: relative;
  overflow: hidden;
  outline-offset: 10px;
  outline: 0px solid #0000;
  border: 1px solid #fff5;
  background-color: #0006;

  transition: all 0.2s cubic-bezier(0.275, 0.485, 0, 1);
}

.pgcr-player-dnf {
  background-color: #0005;
  border: 1px solid #fff2;
  filter: brightness(60%)
}

.pgcr-player .class-icon{
  position: absolute;
  width: 6rem;
  top: -26%;
  left: 2.25rem;
  opacity: 0.45;
  z-index: 0;
}

.pgcr-player:hover, .pgcr-entry:hover {
  outline: 2px solid #fff;
  border: 1px solid #fff;
  background-color: #fff5;
  cursor: pointer;
  outline-offset: 3px;
}

.pgcr-player .emblem{
  z-index: 1;
  box-shadow: 0px 0px 10px #141414;
}

.pgcr-player .details {
  position: absolute;
  left: 5rem;
  top: 12%;
  font-size: 16px;
  letter-spacing: 1px;

  text-shadow: 0px 2px 1px #0004;
}

.pgcr-player .details .bungie-tag{
  font-size: 15px;
}

.pgcr-player .light {
  color: var(--light);
  position: absolute;
  margin-left: .75rem;
  top: .1rem;
  font-size: 14px;

  text-shadow: 0px 3px 2px #0004;
}

.pgcr-badges{
  padding-top: 1.25rem;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
}

.pgcr-badges .badge span{
  font-size: 16px;
  background-color: var(--badge-color);
  padding: 4px 12px 4px 12px;
  border-radius: 4px;
  text-shadow: 0px 2px 1px #0007;
}

.pgcr-badges .badge{
  padding-right: .25rem;
}

.pgcr-player .badges{
  position: absolute;
  left: 5rem;
  bottom: 20%;
  text-align: right;

  display: flex;
}

.pgcr-player .badges .badge {
  padding-right: .25rem;
}

.pgcr-player .badges .badge span{
  font-size: 11px;
  background-color: var(--badge-color);
  padding: 2px 4px 2px 4px;
  border-radius: 2px;
  text-shadow: 0px 1px 2px #0007;
  letter-spacing: 0.2px;
}

.pgcr-player .badges .badge .dnf{
  background-color: var(--badge-color-error);
}

.pgcr-player .badges .badge .dev{
  background-color: var(--badge-color-developer);
}

.pgcr-player .stats{
  position: absolute;
  right: 3rem;
  top: 24%;
  text-align: right;

  display: flex;
}

.pgcr-player .stats .stat{
  font-weight: 400;
  margin-left: 1.5rem;
}

.pgcr-player .stats .stat .stat-name{
  font-weight: 300;
  font-size: 12px;
  opacity: 0.6;
}